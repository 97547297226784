<template>
  <div class="container-fluid">
    <div class="row">
        <div class="col-12 col-lg-6 c g" v-if="deleteakk">
            <div class="card card-body">
                <div class="form-group">
                  <label for="">الحملة</label>
                  <select class="form-control" v-model="hamla_">
                    <option value="1">البشائر</option>
                    <option value="2">الميسر</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="">السكن</label>
                  <select class="form-control" v-model="type_">
                    <option value="1">منى/مكة</option>
                    <option value="2">عرفات</option>
                  </select>
                </div>
                <div class="col-12 text-center g">
                    <button class="btn btn-sm btn-danger" @click="d()">
                        حذف الان
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>
                        ازالة سكن الحجاج <button class="btn btn-sm btn-secondary" @click="deleteakk = !deleteakk">
                            حذف سكن كل الحجاج
                        </button>
                    </h5>
                </div>
                <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-2 g">
                        <span>البحث</span>
                        <input type="text" placeholder="ابحث هنا..." class="form-control" v-model="q" @keyup="page = 0; gett();">
                    </div>
                </div>
                <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
                    <div class="col-12 table-responsive" id='tt'>
                        <table class="table table-hover table-bordered table-sm" id="table">
                            <tr>
                                <th class="dnone">م</th>
                                <th>
                                    الاسم
                                </th>
                                <th>
                                    الهوية
                                </th>
                                <th>
                                    الجوال
                                </th>
                                <th>
                                    رقم الحجز
                                </th>
                                <th>
                                    الحافلة
                                </th>
                                <th>
                                    سكن منى
                                </th>
                                <th>
                                    سكن عرفات
                                </th>
                            </tr>
                            <tbody>
                                <tr v-for="(user, i) in users" :key="user._id">
                                    <td class="dnone">{{ i + 1 }}</td>
                                    <td>
                                        {{ user.name }}
                                    </td>
                                    <td>
                                        {{ user.number }}
                                    </td>
                                    <td>
                                        {{ user.phone }}
                                    </td>
                                    <td>
                                        {{ user.order_id }}
                                    </td>
                                    <td>
                                        {{ user.bus_obj.title }}
                                    </td>
                                    <td>
                                        {{ user.camp_obj.title }}
                                        <span v-if="user.camp_obj.title">
                                            <button class="btn btn-danger btn-sm" @click="deletex(1, user)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </span>
                                    </td>
                                    <td>
                                        {{ user.camp2_obj.title }}
                                        <span v-if="user.camp2_obj.title">
                                            <button class="btn btn-danger btn-sm" @click="deletex(2, user)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data(){
    return {
      user: JSON.parse(localStorage.getItem("user")),
      users: [],
      loading: false,
      printing: false,
      current: {},
      manual_location: false,
      deleteakk: false,
      q: "",
      hamla: "all",
      page: 0,
      status: "all",
      statuses: [],
      bus_id: "all",
      status_for_delete: "",
      start_location: "all",
      camps: [],
      busses: [],
      camps_obj: {},
      busses_obj: {},
      cities: [],
      gender: "all",
      camp_id: "all",
      camp2_id: "all",
      message: "",
      hamla_: "1",
      type_: "1",
      all: false
    }
  },
  created(){
    if(!checkPer('reset-camp')){
        this.$router.push('/pers')
        return;
    }
    var g = this;
    g.gett()
  },
  methods: {
    d(){
        var g = this;
        if(!checkPer('*')){
            this.$router.push('/pers')
            return;
        }
        $.post(api + '/admin/users/reset-all-camp', {
            jwt: g.user.jwt,
            hamla: g.hamla_,
            type: g.type_
        }).then(function(a){
            alert('تم بنجاح', 100)
                g.gett()
        })

    },
    deletex(type, user){
        if(confirm(`متأكد من ازالة سكن ${type == 1 ? 'منى' : 'عرفات'} للحاج ${user.name}؟`)){
            var g = this;
            if(type == 1){
                user.camp_id = ""
            }else{
                user.camp2_id = ""
            }
            $.post(api + '/admin/users/save', {
                jwt: g.user.jwt,
                id: user._id,
                user: user
            }).then(function(a){
                alert('تم بنجاح', 100)
                g.gett()
            })
        }
    },
    gett(){
      var g = this;
      $.post(api + '/admin/users/list', {
        all: g.all,
        jwt: this.user.jwt,
        q: this.q,
        hamla: this.hamla,
        page: this.page,
        status: this.status,
        start_location: this.start_location,
        bus_id: this.bus_id,
        gender: this.gender,
        camp_id: this.camp_id,
        camp2_id: this.camp2_id
      }).then(function(a){
        g.users = a.response;
      })
    },
  }
}
</script>

<style>
#dropdown-1x__BV_toggle_{
    padding: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-radius: 0px;
}
</style>
